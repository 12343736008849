import React from "react"
import BrandParagraph from "../atoms/BrandParagraph"
import IconBox from "../atoms/IconBox"
import SectionTitle from "../atoms/SectionTitle"

const WhatWeOfferSection = ({ data }) => {
  return (
    <div className="bg-primary-light pt-16 pb-16">
      <div className="cc-section--horizontal-space">
        <SectionTitle
          title={data.sectionTitle}
          titleEC={`justify-center`}
          componentEC={`text-center`}
        />
        <BrandParagraph text={data.description} textEC={`text-center ${data.descriptionEC}`} />
        {data?.iconBoxes && (
          <div className="flex flex-wrap justify-between gap-[30px] mt-8 md:mt-5">
            {data.iconBoxes.map((item, index) => (
              <IconBox
                key={"whatweofferbox_key" + index}
                componentEC={`w-[48%]`}
                image={item.iconImage}
                imageComponentEC={`w-[52px] h-[52px] min-w-[52px] min-h-[52px] ${item.imageComponentEC}`}
                imageEC={`max-w-[24px]`}
                title={item.title}
                titleEC={`!mb-0`}
                description={item.description}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default WhatWeOfferSection
