import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { contactSection } from "../../content/Common"
import ContentSectionWithImage from "../../components/common/ContentSectionWithImage"
import ContactSection from "../../components/common/ContactSection"
import InnerHeader from "../../components/molecules/InnerHeader"
import HouseDetailsSection from "../../components/common/HouseDetailsSection"
import WhatWeOfferSection from "../../components/common/WhatWeOfferSection"
import CTASectionHorizontal from "../../components/molecules/CTASectionHorizontal"
import FaqsSection from "../../components/common/FaqsSection"
import { propertyManagementPage } from "../../content/Services"

const PropertyManagement = () => (
  <Layout>
    <Seo title="Property Management" />

    <InnerHeader
      title={propertyManagementPage.title}
      googleReviewText={propertyManagementPage.googleReviewText}
      description={propertyManagementPage.description}
    />

    <ContentSectionWithImage
      componentEC={`md:!pt-0`}
      data={
        propertyManagementPage.propertyManagementServiceBetterReturnsLessStressSection
      }
    />

    <WhatWeOfferSection data={propertyManagementPage.whatWeOfferSection} />

    <HouseDetailsSection
      data={propertyManagementPage.whyChooseCarecasaSection}
    />

    <CTASectionHorizontal data={propertyManagementPage.ctaSection} />

    <FaqsSection faqs={propertyManagementPage.faqs} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default PropertyManagement
